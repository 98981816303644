import StackTrace from 'stacktrace-js';
import jwtDecode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardActions, CardContent, CardHeader, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';

import Header from '../Header';
import Loading from '../Loading';
import TenantSelector from '../TenantSelector';
import UserLogin from '../UserLogin';
import Workflows from '../Workflows';
import RocketImg from '../../img/rocket.png';

import {
    getTenants,
    getUserPoolAll,
    getWorkflows,
    hasTokenExpired,
    startWorkflow,
    getMFPUserSettings,
    setMFPUserSettings,
    refreshToken as refreshT,
    isVersionValid
} from '../../services/api';

const useStyles = makeStyles({
    controls: {
        display: 'inline-flex',
        justifyContent: 'end',
        width: '73%'
    },
    header: {
        background: 'black',
        color: 'white',
        height: 32,
        padding: 8
    },
    logo: {
        display: 'inline-flex'
    },
    resultContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        '& *': {
            margin: '12px'
        }
    },
    resultText: {
        maxWidth: '600px',
        textAlign: 'center'
    },
    title: {
        fontSize: '20px',
        margin: 8
    },
    toolbar: {
        alignContent: 'space-around',
        background: 'lightgray',
        display: 'flex',
        height: '48px',
        justifyContent: 'flex-end'
    },
    page: {
        position: 'relative',
        top: 65
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '75vw',
        height: '52vh',
        position: 'absolute',
        top: '26%',
        left: '12.5%',
        zIndex: '5'
    },
    cardBackground: {
        background: '#00000060',
        width: '100%',
        height: '100%',
        top: '0',
        position: 'absolute',
        zIndex: '3'
    },
    debugCard: {
        width: '90%',
        position: 'absolute',
        top: '0',
        left: '5%',
        zIndex: '5'
    },
    debugLine: {
        margin: '8px 0'
    },
    body: {
        overflow: 'hidden'
    }
});

const MFP = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [stratus] = useLocalStorage("stratus", false);
    const ns = stratus ? 'stratus' : 'scantrip';
    const { t } = useTranslation(ns);
    const [wfx, setWFX] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [tenants, setTenants] = useState(undefined);
    const [noDipa, setNoDipa] = useLocalStorage("noDipa", false);
    const [user, setUser] = useLocalStorage('user', '');
    const [tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [tenantSelected, setTenantSelected] = useState(false);
    const [accessToken, setAccessToken] = useLocalStorage('accessToken', undefined);
    const [idToken, setIdToken] = useLocalStorage('idToken', undefined);
    const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', undefined);
    const [client, setClient] = useLocalStorage('client', undefined);
    const [errorText, setErrorText] = useState(undefined);
    const [errorFunction, setErrorFunction] = useState(undefined);
    const [debugDisplay, setDebugDisplay] = useState(false);
    const [debugHash, setDebugHash] = useLocalStorage('debugHash', undefined);
    const [settingsFetched, setSettingsFetched] = useState(false);
    const [appVersion] = useLocalStorage('appVersion', '')
    const [debugHashOverride] = useLocalStorage('debugHashOverride', false);

    const showError = (text, fn) => {
        setErrorText(text);
        setErrorFunction(fn);
    }

    // eslint-disable-next-line
    const closeError = () => {
        setErrorText(undefined);
    }

    const saveUserInfoToMFP = async (settings={ accessToken, idToken, refreshToken, user, client, tenant }) => {
        await setMFPUserSettings(settings);
    }

    const checkAccessToken = async () => {
        try {
            if (!accessToken || !tenant) {
                console.log('checkAccessToken: called and accessToken/tenant not set!', accessToken, tenant);
                return null;
            }

            if (noDipa && hasTokenExpired(accessToken, 1800)) {
                console.log('Refreshing token');

                try {
                    const tokenResp = await refreshT(tenant.slug, refreshToken, client.ClientId, client.ClientSecret, tenant.region);
                    setIdToken(tokenResp.id_token);
                    setAccessToken(tokenResp.access_token);
                    await saveUserInfoToMFP({
                        accessToken: tokenResp.access_token,
                        idToken: tokenResp.id_token,
                        refreshToken,
                        user,
                        client,
                        tenant
                    });
                    return tokenResp.access_token;
                } catch (e) {
                    return null;
                }
            }

            return accessToken;
        } catch (e) {
            console.error(e);
            alert('Error refreshing token');
            alert(e);
            const callback = (stackframes) => {
                const stringifiedStack = stackframes.map(sf => {
                    return sf.toString();
                }).join('\n');
                alert(stringifiedStack);
            }
            const errback = (_) => {
            }
            StackTrace.fromError(e).then(callback).catch(errback);
        }
    }

    const start = async (id) => {
        // Make request to start workflow
        setIsLoading(true);
        try {
            const token = await checkAccessToken();
            if (!token) {
                setIsLoading(false);
                showError(t('Your session has expired. Please log in again to continue.'), () => logout);
                return;
            }
            const response = await startWorkflow(id, tenant, token, !noDipa, stratus);
            if (response.error !== undefined) {
                if (response.status === 403) {
                    if (response.body.licenseUsage) {
                        showError(t('You have reached a daily limit for your Dispatcher ScanTrip Cloud Trial License. Trial Licenses are limited to 50 files or 10 MB total per day.'),
                        () => cbLogout);
                    } else if (response.body.expired) {
                        showError(t('Your license has expired. To continue using Dispatcher ScanTrip Cloud, please contact your Konica Minolta representative about upgrading your license.'),
                        () => cbLogout);
                    } else if (response.body.unlicensed) {
                        showError(t('The device you are using does not have a Dispatcher ScanTrip Cloud Device License assigned to it. Please add a Device License to this device to continue.'),
                        () => cbLogout);
                    }
                }
                return;
            }
            if (response['$metadata'].httpStatusCode !== 200) {
                // TODO Error handle this
            }
            history.push('/processing', { arn: response.executionArn, token: response.token, url: response.url })
        } catch (e) {
            console.error(e);
            alert(e);
            const callback = (stackframes) => {
                const stringifiedStack = stackframes.map(sf => {
                    return sf.toString();
                }).join('\n');
                alert(stringifiedStack);
                setIsLoading(false);
            }
            const errback = (_) => {
                setIsLoading(false);
            }
            StackTrace.fromError(e).then(callback).catch(errback);
        }
    }

    const clearLocationState = () => {
        history.replace({ ...history.location, state: undefined });
    }
    const cbClearLocationState = useCallback(clearLocationState, [history]);

    const workflowResult = location.state?.success;
    const workflowList = () => {
        return (
            isLoading ? (
                <Loading />
            ) : (
            <>
                <Workflows tenant={tenant} workflows={wfx} startWfx={start} />
            </>
            )
        )
    }
    const result = () => {
        return (
            workflowResult ? (
                <div className={classes.resultContainer}>
                    <img src={RocketImg} alt="Success" />
                    <div className={classes.resultText}>{t('Your document has been scanned, processed, and sent to the selected destination.')}</div>
                    <Button variant="contained" onClick={() => clearLocationState()}>{t('Continue')}</Button>
                </div>
            ) : (
                <div className={classes.resultContainer}>
                    <div className={classes.resultText}>{t('An error has occurred while processing your document. Please consult the workflow logs to determine the location and nature of the error.')}</div>
                    <Button variant="contained" onClick={() => clearLocationState()}>{t('Continue')}</Button>
                </div>
            )
        );
    }

    const selectTenant = async (t) => {
        if (!t) {
            return
        }
        setIsLoading(true);
        const resp = await getUserPoolAll(t.slug, t.region);
        t.userPoolId = resp.poolId;
        t.clientId = resp.clientId;
        t.userPool = resp.userPool;
        t.client = resp.client;
        setTenantSelected(true);
        setTenant(t);
    }

    const logout = async () => {
        cbClearLocationState();
        setSettingsFetched(false);
        history.push("/logout");
    }

    const cbLogout = useCallback(logout, [cbClearLocationState, history, setSettingsFetched]);

    const goToMarketPlace = () => {
        window.location = "http://127.0.0.1:8090/0A0243A9";
    }

    const redirect = () => {
        setIsLoading(true);
        history.push('./dipa');
    }

    const pageSelect = () => {
        if (!tenant) {
            return <TenantSelector tenants={tenants} loading={isLoading} onClick={selectTenant} />
        } else if (!user && !location.state?.user) {
            if (tenantSelected) {
                return <UserLogin tenant={tenant} />
            } else {
                setTenant(undefined);
                setIsLoading(false);
            }
        } else {
            if (isLoading) {
                setIsLoading(false);
            }
            return workflowResult === undefined ? workflowList() : result()
        }
    }

    const errorDialog = () => {
        if (!errorText) {
            return <></>
        }
        return (
            <div>
                <Card className={classes.card}>
                    <CardHeader
                        avatar={<InfoIcon color="primary" fontSize="large" />}
                        title={<Typography sx={{ fontSize: 'larger' }}>{t('Information')}</Typography>}
                    />
                    <CardContent sx={{ padding: '0' }}>
                        <Typography sx={{ padding: '0 66px', whiteSpace: 'pre-line', fontSize: '1.1rem' }}>
                            {errorText}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing sx={{ mt: 'auto', justifyContent: 'flex-end' }}>
                        <Button variant="contained" sx={{ padding: '8px 32px', margin: '8px' }} onClick={() => { errorFunction() } }>{t('OK')}</Button>
                    </CardActions>
                </Card>
                <div className={classes.cardBackground}></div>
            </div>
        );
    }

    const displayDebug = () => {
        if (!debugDisplay) {
            return <></>
        }
        let decodedAccessToken = {}
        if (accessToken) {
            decodedAccessToken = jwtDecode(accessToken);
        }
        return (
            <div>
                <Card className = {classes.debugCard}>
                    <CardContent>
                        <div>
                            <div className = {classes.debugLine}>{`Debug display: ${debugDisplay}`}</div>
                            <div className = {classes.debugLine}>{`Domain: ${window.location.hostname}`}</div>
                            <div className = {classes.debugLine}>{`Version: ${appVersion}`}</div>
                            <div className = {classes.debugLine}>{`isLoading: ${isLoading}`}</div>
                            <div className = {classes.debugLine}>{`wfx: ${JSON.stringify(wfx)}`}</div>
                            <div className = {classes.debugLine}>{`tenants: ${JSON.stringify(tenants)}`}</div>
                            <div className = {classes.debugLine}>{`noDipa: ${noDipa}`}</div>
                            <div className = {classes.debugLine}>{`user: ${user}`}</div>
                            <div className = {classes.debugLine}>{`tenant: ${JSON.stringify(tenant)}`}</div>
                            <div className = {classes.debugLine}>{`tenantSelect: ${tenantSelected}`}</div>
                            <div className = {classes.debugLine}>{`accessToken: ${accessToken}`}</div>
                            <div className = {classes.debugLine}>{`decodedAccessToken: ${JSON.stringify(decodedAccessToken)}`}</div>
                            <div className = {classes.debugLine}>{`Date.now(): ${Math.floor(Date.now() / 1000)}`}</div>
                            <div className = {classes.debugLine}>{`refreshToken: ${refreshToken}`}</div>
                            <div className = {classes.debugLine}>{`client: ${JSON.stringify(client)}`}</div>
                            <div className = {classes.debugLine}>{`errorText: ${errorText}`}</div>
                            <TextField
                                id="debug-hash-input"
                                variant="outlined"
                                size="small"
                                value={debugHash}
                                onChange={(event) => setDebugHash(event.target.value)}
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
        )
    }

    const cbSelectTenant = useCallback(selectTenant, [setTenant, setTenantSelected]);
    // eslint-disable-next-line
    const cbCheckAccessToken = useCallback(checkAccessToken, [accessToken, client, tenant, refreshToken, setAccessToken, setIdToken, user]);
    useEffect(() => {
        // Check if app version number is up to date
        // TODO Fix version check with Paragon
        if (!isVersionValid(appVersion) && noDipa) {
            showError(t('An update to your app is required to continue.'), () => goToMarketPlace);
            return;
        }
        const stateTenant = location.state?.tenant;
        const stateUser = location.state?.user;
        if(location.state?.noDipa ){
            setNoDipa(true);
        }
        const stateAccessToken = location.state?.accessToken;
        const stateIdToken = location.state?.idToken;
        const stateRefreshToken = location.state?.refreshToken;
        const stateClient = location.state?.client;
        if (stateTenant && stateUser) {
            setUser(stateUser);
            setTenant(stateTenant);
            setAccessToken(stateAccessToken);
            setIdToken(stateIdToken);
            setRefreshToken(stateRefreshToken);
            setClient(stateClient);
            saveUserInfoToMFP({
                accessToken: stateAccessToken,
                idToken: stateIdToken,
                refreshToken: stateRefreshToken,
                user: stateUser,
                client: stateClient,
                tenant: stateTenant
            });
        }
        // This function exists to satisfy a React warning about useEffect functions being synchronous
        async function fetchTenants() {
            let appId = '0A024AC9';
            if (stratus) {
                appId = '0A024ADM';
            }
            const tlist = await getTenants(appId);
            const filteredList = tlist.filter(t => stratus ? t?.plan === 'stratus' : t?.plan === 'stc');
            setTenants(filteredList);
            if (filteredList.length === 1) {
                cbSelectTenant(filteredList[0]);
            } else {
                setIsLoading(false);
            }
        }
        async function getSettings() {
            const settings = await getMFPUserSettings();
            let settingsUpdated = false;
            if (settings?.userData?.refreshToken) {
                setRefreshToken(settings?.userData?.refreshToken);
                settingsUpdated = true;
            }
            if (settings?.userData?.client) {
                setClient(settings?.userData?.client);
                settingsUpdated = true;
            }
            if (settings?.userData?.tenant) {
                setTenant(settings?.userData?.tenant);
                settingsUpdated = true;
            }
            if (settings?.userData?.idToken) {
                setIdToken(settings?.userData?.idToken);
                settingsUpdated = true;
            }
            if (settings?.userData?.accessToken) {
                setAccessToken(settings?.userData?.accessToken);
                settingsUpdated = true;
            }
            if (settings?.userData?.user) {
                setUser(settings?.userData?.user);
                settingsUpdated = true;
            }
            if (settingsUpdated) {
                window.location.reload();
                settingsUpdated = true;
            }
        }
        async function getSettingsAndTenants() {
            // First check if user "keep logged in" token is stored on the MFP
            if (!accessToken) {
                await getSettings();
            }
            if (tenants === undefined && tenant === undefined) {
                await fetchTenants();
            }
        }
        getSettingsAndTenants();
    // eslint-disable-next-line
    }, [
        cbSelectTenant,
        location,
        setAccessToken,
        setClient,
        setIdToken,
        setRefreshToken,
        setTenant,
        setUser,
        tenants,
        tenant,
        setNoDipa,
        settingsFetched,
        setSettingsFetched,
        appVersion
        ]);

    useEffect(() => {
        async function fetchWorkflows() {
            try {
                const token = await cbCheckAccessToken();

                if (token && tenant) {
                    let appId = '0A024AC9';
                    if (stratus) {
                        appId = '0A024ADM';
                    }
                    const workflowResp = await getWorkflows(token, tenant.region, appId);
                    // TODO Different errors for different responses
                    if (workflowResp.status !== 200) {
                        showError(t('Your session has expired. Please log in again to continue.'), () => cbLogout);
                        return;
                    }
                    setWFX(workflowResp.workflows);
                } else {
                    setWFX([]);
                }

                setIsLoading(false);
            } catch (e) {
                console.error(e);
            }
        }
        if (!accessToken) {
            return;
        } else if (tenant && !Array.isArray(wfx)) {
            fetchWorkflows();
        }
    // eslint-disable-next-line
    }, [
        accessToken,
        cbCheckAccessToken,
        cbLogout,
        tenant,
        wfx,
        t
    ]);

    const handleKeyPress = useCallback((event) => {
        if (event.ctrlKey && event.code === "KeyJ") {
            setDebugDisplay(!debugDisplay);
        }
    }, [debugDisplay]);

    useEffect(() => {
        if (process.env.REACT_APP_STAGE === undefined || process.env.REACT_APP_STAGE === 'DEV' || debugHashOverride) {
            document.addEventListener('keydown', handleKeyPress);
        }
    }, [handleKeyPress, debugHashOverride]);

    useEffect(() => {
        document.body.classList.add(classes.body);
    }, [classes.body]);

    return (
        <>
            <Header user={user} tenant={tenant?.name} logout={logout} redirect={redirect} noDipa={noDipa} loading={isLoading} />
            <div className={classes.page}>{pageSelect()}</div>
            <div>{errorDialog()}</div>
            <div>{displayDebug()}</div>
        </>
    )
}

export default MFP